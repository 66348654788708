.loading_overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_icon{
  transform: scale(2)
}
