.button{
  padding: 10px 25px;
  background: none;
	color: inherit;
	border: none;
	cursor: pointer;
  outline: inherit;

  div{
    display: flex;
    align-items: center;
  }

  &:hover {
    color: #ff810a;
    cursor: pointer;
  }

  &.activeButton {
    div{
      transition: transform 0.3s;
      transform: translateX(15px)
    }

  }

}
