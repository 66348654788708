.container {
  display:flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.input {
  background-color: white;
  border: none;
  flex: auto;
}

.errorText {
  color: #f44336;
}

.errorTextWrapper {
  padding: 5px 5px 0 5px;

  span {
    color: #f44336;
  }
}

.forgotPasswordLink{
  &:hover{
    cursor: pointer;
  }
}
