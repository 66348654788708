.loading_container{
  width: 100%;
  min-width: 670px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_icon{
  //transform: scale(2)
}
