.searhStudentsContainer{
  width: 100%;
  max-width: 2000px;
}

.searchContainer{
  padding: 5px;
  padding-top: 20px;
  display: flex;
  width: 500px;

  div{
    flex: 1;
  }
}

.search {
  height: 50px;
  min-width: 100px;
  max-width: 350px;
  width: 100%;
  border: 1.2px solid #d3d4d6;
  border-radius: 200px;
  background: transparent;
  padding: 0 30px;
  color: #c0c1c3;
  margin-bottom: 20px;
  margin-left: 15px;

  &::placeholder{
    color: #c0c1c3;
  }
}

.tableLine{
  transition: background-color 0.3s;
}
.tableLine:hover {
  cursor: pointer;
  background: #f3f3f3;
}
