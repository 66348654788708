.uploadButton{
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: none;
  outline: none;
  padding: 7px 12px;
  font-size: 15px;
  border-radius: 5px;
  color: #525f7f;
  transition: background-color 0.2s;

  &:hover{
    cursor: pointer;
    background-color: #fbfbfb;
  }
}

.uploadInput{
  display: none;
}

.tableLine{
  transition: background-color 0.3s;
}
.tableLine:hover {
  cursor: pointer;
  background: #f3f3f3;
}

.removeButton{
  background: none;
  border: none;
  padding: 5px;

  &:hover{
    cursor: pointer;
  }
}

.checkBoxesContainer{
  margin-bottom: 50px;

  span{
    color: white;
  }
}

