.container{
  height: 100%;
  padding: 10px;
}

.tableLine{
  transition: background-color 0.3s;
}
.tableLine:hover {
  cursor: pointer;
  background: #f3f3f3;
}

.checkBoxesContainer{
  margin-bottom: 50px;

  span{
    color: white;
  }
}

.search {
  height: 50px;
  min-width: 100px;
  max-width: 350px;
  width: 100%;
  border: 1.5px solid #fafafa;
  border-radius: 200px;
  background: transparent;
  padding: 0 30px;
  color: #fff;
  margin-bottom: 20px;
  margin-left: 15px;

  &::placeholder{
    color: #fff;
  }
}

.backgroundTop{
  position: absolute;
  background: linear-gradient(87deg, #ffa230  0, #ff810a  100%);
  height: 200px;
  width: 100%;
}

.checkBoxContainer{
  margin-left: 30px;
  margin-top: 15px;
}

