.form{
  display: flex;
  flex-direction: column;
  padding: 5px 7px;
}

.radio{
  background: none;
  outline: none;
  border: none;

  label{
    display: flex;
    align-items: center;

    .outlineCircle{
      border: 1px solid #5e72e4;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle{
        background-color: #5e72e4;
        width: 8px;
        height: 8px;
        border-radius: 5px;

      }
    }



    span{
      margin-left: 5px;
      color: #525f7f;
    }
  }
}
