.container{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 5px;
}

.error{
  font-size: smaller;
  color: red;
  margin: 4px 0 0 4px;
}

.input{
  &::placeholder {
    color: red;
  }
}

.dateTimePicker{
  position: absolute;
  z-index: 999;
  margin-top: 80px;
}
